<template>
  <Page title="Reset your password">
    <form
      class="flex flex-col w-full sm:w-96 mx-auto"
      @submit.prevent="handlePasswordReset">
      <h1>Reset Password</h1>
      <p>Enter your email address and we will send you a link to reset your password</p>
      <Input
        v-model="email"
        type="email"
        placeholder="Email"
      />
      <Button
        class="max-w-xs mx-auto"
        buttontype="background"
        @click="handlePasswordReset"
        type="button"
      >
        Reset Password
      </Button>
    </form>
  </Page>
</template>
<script>
import Page from '@/components/page.vue'
import Button from '@/components/button.vue'
import Input from '@/components/input.vue'
import { apiRequest } from '../components/js/utils'
import { mapState } from 'vuex'

export default {
  components: { Page, Button, Input },
  name: 'PasswordReset',
  data () {
    return {
      email: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  created () {
    if (this.user.isAuthenticated) {
      this.$router.push('/')
    } else {
      this.$store.dispatch('getUser').then(() => {
        if (this.user.isAuthenticated) {
          this.$router.push('/')
        }
      })
    }
  },
  methods: {
    async handlePasswordReset () {
      try {
        await apiRequest.post('password-reset/', {
          email: this.email
        })
        this.$store.commit('addAlert', {
          status: 'success',
          message: 'Password reset email sent',
          timer: 3000
        })
        this.$router.push('/')
      } catch (e) {
        this.$store.commit('addAlert', {
          status: 'error',
          message: 'An error occured whilst attempting to reset your ' +
            'password. Please try again',
          timer: 3000
        })
      }
    }
  }
}
</script>
